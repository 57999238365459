<script>
import Layout from "@/views/layouts/static/main";
import PageHeader from "@/components/static/page-header";
import appConfig from "@/app.config";
import { required } from "vuelidate/lib/validators";
import MagicTable from "@/components/MagicTable/MagicTable";

/**
 * Add-product component
 */
export default {
  page: {
    title: "Employee Requests",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    MagicTable,
  },
  data() {
    return {
      title: "Team Members",
      teamMembers: [],
      processing:false,
      fetch_url:'/employee/team-member',
      extraFilters:{employee_status:"request_employee"},
      dataForModal: { under_eighteen_docs: null },
      items: [
        {
          text: "Employee",
        },
        {
          text: "Requests",
          active: true,
        },
      ],
      form: {
        allEmployee: null,
        checked: [],
      },
      submitted: false,
      action_detail: null,
      employee_id: null,
      action_status: null,
      action_modal : false,
      payment: null,
      selected_payment : null,
      selectedEmp : null,
    };
  },

  // validations: {
  //   action_detail: {
  //     required,
  //   },
  // },

  methods: {

    updateRecords(records) {
            this.teamMembers = records;
        },

    rolesToString(areaRoles) {
      let name_array = areaRoles.map((role) => {
        return role.name;
      });
      let string = name_array.toString();

      return string;
    },

    isMinor(data) {
      return data == 1 ? "Yes" : "No";
    },
    showLocation(locations)
    {
      let location_array = locations.map((loc) => {
        return loc.name;
      });
      let string = location_array.join(' , ').toString();
      return string;
    },
    showWorkArea(areaRoles)
    {
      let areaRolesArray = [];
      areaRoles.forEach(element => {
        let found = areaRolesArray.findIndex(workAreaName => workAreaName == element.area.name);
        if(found < 0)
        {
          areaRolesArray.push(element.area.name);
        }
      });
      return areaRolesArray.toString();
    },

    action(employee, status) {
      this.selectedEmp = employee;
      this.employee_id = employee.id;
      this.action_status = status;
    },

    actiomSubmit() {
      let self = this;
      self.submitted = true;
      self.action_modal = true;
      // if(self.action_status == 'Inactive')
      // {
      //   self.$v.$touch();
      //   if (self.$v.$invalid) {
      //     return;
      //   } 
      // }
      if (self.processing) 
      {
        return;
      }
      self.processing = true;
      self.$axios
        .post("/employee/team-member/action", {
          id: self.employee_id,
          status: self.action_status,
          action_detail: self.action_detail,
          payment: self.payment
        })
        .then((response) => {
          let data = response.data;

          self.triggerSwal(data.message, "success");
          self.$bvModal.hide('modal-reason');
          self.teamMembers.map((member) => {
            if(member.employee.id==self.employee_id){
              member.employee.payment= self.payment;
              member.employee.status=self.action_status;
            }else{
              member;
            }
            return member;
            // return member.employee.id==self.employee_id ?  member.employee.status=self.action_status : member 
          });
        })
        .catch((error) => {
          self.triggerSwal(error, "error");
          this.handleErrorResponse(error.response, "error");
        })
        .finally(() => {
          self.processing=false;
          self.action_detail=null;
          self.action_modal = false;
          // self.$v.$reset();
        });
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :items="items" :title="title"/>
   
    <div class="row">
      <div class="col-lg-12">
      
        <div class="card dashboard__wrapper">
        <div class="card-body pt-0">
          <span class="primaryColor">Note : It is important to only accept requests from individuals who have verified their Phone number.</span>
         <magic-table :fetch_url_prop="fetch_url" :extraFilters="extraFilters"  @recordsUpdated="updateRecords">

            <template v-slot:table>
            <div class="table-responsive mt-3 table-nowrap">
              <table class="table mb-0 table-bordered">
                <thead>
                  <tr>
                    <th class="f-14 roboto">Employee Name</th>
                    <th class="f-14 roboto">Availability</th>
                    <th class="f-14 roboto">Experience</th>
                    <th class="f-14 roboto">Locations</th>
                    <th class="f-14 roboto">Work Area</th>
                    <th class="f-14 roboto">Email</th>
                    <th class="f-14 roboto">Phone</th>
                    <th class="f-14 roboto">Phone Verified</th>
                    <th class="f-14 roboto">Roles/Positions</th>
                    <th class="f-14 roboto">Minor</th>
                    <th class="f-14 roboto">Request Date</th>
                    <th class="f-14 roboto">Status</th>
                    <th class="f-14 roboto">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(member, index) in teamMembers" :key="index">
                    <template v-if="member.employee.status=='Pending' || member.employee.status=='Updated' || member.employee.status=='Inactive'">
                    <td class="f-14 roboto align-middle">{{ member.name }}</td>
                    <td class="f-14 roboto align-middle">
                      {{ member.employee.employee_type }}
                    </td>
                    <td  class="f-14 roboto align-middle"> {{ member.employee.experience_level }} </td>
                    <td  class="f-14 roboto align-middle"> {{ showLocation(member.employee.locations) }} </td>
                    <td class="f-14 roboto align-middle">
                      <template v-if="member.employee.area_roles.length>0">
                      {{showWorkArea(member.employee.area_roles)}}
                        </template>
                        <template v-else>
                            N/A
                        </template>
                    </td>
                    <td class="f-14 roboto align-middle">{{ member.email }}</td>
                    <td class="f-14 roboto align-middle">{{ member.phone }}</td>
                    <td class="f-14 roboto align-middle">{{ member.is_phone_verified == 0 ? 'No' : 'Yes' }}</td>
                    <td class="f-14 roboto align-middle">
                      <template v-if="member.employee.area_roles.length>0">
                          {{ rolesToString(member.employee.area_roles) }}
                        </template>
                        <template v-else>
                            N/A
                        </template>
                    </td>
                    <td class="f-14 roboto align-middle">
                      {{ isMinor(member.employee.minor) }}
                      <i
                        @click="dataForModal = member.employee"
                        v-b-modal.modal-minor
                        :class="{ 'fas fa-eye': member.employee.minor == 1 }"
                      ></i>
                    </td>
                    <td class="f-14 roboto align-middle">
                      {{ DateWithMonthName(member.created_at , 'ddd, MMM DD,YYYY hh:mm A') }}
                    </td>
                    <td class="f-14 roboto align-middle">
                      {{ member.employee.status }}
                    </td>
                    <td class="f-14 roboto align-middle">
                      <div
                        v-if="member.employee.status != 'Active'"
                        class="action-buttons d-flex justify-content-between"
                      >
                        <button
                          class="btn-outline-success btn py-1"
                          @click="action(member.employee, 'Active')"
                          title="Accept"
                          v-b-modal.modal-reason

                        >
                          <i class="fas fa-check-circle"></i>
                        </button>
                        <button
                          v-if="member.employee.status != 'Inactive'"
                          class="btn-outline-danger btn py-1"
                          @click="action(member.employee, 'Inactive')"
                          title="Reject"
                          v-b-modal.modal-reason
                        >
                          <i class="fas fa-times-circle"></i>
                        </button>
                      </div>
                      <div class="text-black-50" v-else>N/A</div>
                    </td>
                    </template>
                  </tr>
                </tbody>
              </table>
            </div>
            </template>
          </magic-table>
        </div>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-minor"
      size="lg"
      title="Minor Attachment"
      title-class="font-18"
      hide-footer
      body-class="p-3" 
    >
      <div class="row">
        <div class="col-lg-12 border">
          <iframe
            v-if="dataForModal.under_eighteen_docs != null"
            :style="{ height: '500px' }"
            width="100%"
            :src="assets_url + dataForModal.under_eighteen_docs"
          >
          </iframe>
          <div v-else class="f-14 roboto align-middle">
            No Attachment Added from Employee
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="modal-reason"
      size="lg"
      title="Action Detail"
      title-class="font-18"
      hide-footer
      v-model="action_modal" 
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
    >
      <b-form>
        <div class="row">
          <div class="">
            <p class="fw-bold" v-if="selectedEmp?.status == 'Updated'">Current Salary Status : {{selectedEmp?.payment ? '$'+selectedEmp?.payment +' Per Hour' : 'Role Pay Per Hour'}} </p>
            <div v-if="action_status == 'Active'" class="mb-3">
              <p>Select Salary Option</p>
              <select class="form-select" v-model="selected_payment">
                <option value="base_salary">Base salary per hour</option>
                <option :value="null">Role salary per hour</option>
              </select>
            </div>

            <div>
              <input type="number" class="form-control" placeholder="Enter hourly salary" v-model="payment" v-if="selected_payment== 'base_salary'">
            </div>
            
            <label>Detail</label>
            <div>
              <textarea
                v-model="action_detail"
                class="form-control"
                rows="5"
              ></textarea>
              <!-- <div
                v-if="submitted && !$v.action_detail.required" class="invalid-feedback">
                Detail is required.
              </div> -->
            </div>
             <div class="mt-3 col-md-12">
                <SubmitButton :processing="processing" text="Submit" @clicked="actiomSubmit" />
                <button type="button" class="btn btn-outline-pink mx-3" @click="action_modal=false;"> Close </button>
            </div>
          </div>
        </div>
      </b-form>
    </b-modal>
  </Layout>
</template>
